import { Suspense } from "react"
import Loading from "js/components/loading"
import { getDisplayName } from "./utils"

export default function withSuspense(Component) {
  function C(props) {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    )
  }

  C.displayName = `WithSuspense(${getDisplayName(Component)})`

  return C
}
