import { createRoot } from "react-dom/client"
import withResizeMessage from "js/hoc/with_resize_message"
import ClientLayout from "js/components/client_layout"
import "css/fonts.css"

const App = withResizeMessage(ClientLayout)

try {
  const layoutConfigScript = document.getElementById("layout-config")
  const layoutConfig = JSON.parse(layoutConfigScript.innerText)
  const grid = JSON.parse(layoutConfig.grid)
  const organization = Number(layoutConfig.organization)
  const root = createRoot(document.getElementById("layout"))
  root.render(<App grid={grid} organization={organization} />)
} catch (error) {
  console.error(error)
}
