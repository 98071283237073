export function federatedElectionUrl(date) {
  return new URL(`${date}/remoteEntry.js`, FEDERATED_ELECTION_CODE_BASE_URL)
    .href
}

export function electionScope(date) {
  return `election_${date.replace(/-/g, "_")}`
}

export function loadDynamicScript(url) {
  return new Promise((resolve, reject) => {
    const element = document.createElement("script")

    fetch(url, {
      method: "HEAD",
      headers: {
        Pragma: "no-cache",
        "Cache-Control": "no-cache",
      },
    }).then((res) => {
      const modified = res.headers.get("last-modified")

      element.src = `${url}?modified=${modified}`
      element.type = "text/javascript"
      element.async = true

      element.onload = () => {
        console.log(`Dynamic script loaded from ${url}`)
        document.head.removeChild(element)
        resolve()
      }

      element.onerror = (error) => {
        console.error(`Dynamic script failed from ${url}`, error)
        document.head.removeChild(element)
        reject(error)
      }

      document.head.appendChild(element)
    })
  })
}

export async function loadElectionContainer(date) {
  const scope = electionScope(date)
  if (!window[scope]) {
    const url = federatedElectionUrl(date)
    await loadDynamicScript(url)
  }
  return window[scope]
}

export async function loadElectionModule(container, module) {
  const factory = await container.get(module)
  const Module = factory()
  return Module
}
