import styled from "styled-components"
import { fontRegular } from "js/utils/styles"
import PropTypes from "prop-types"

const Container = styled.div`
  margin: 10px auto;
  text-align: center;
  ${fontRegular}
`

function Loading({ customClass }) {
  return <Container className={customClass}>Loading...</Container>
}

Loading.propTypes = {
  customClass: PropTypes.string,
}

export default Loading
