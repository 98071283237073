import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.div`
  ${(props) => props.styles}
`

function CustomCSSWrapper({ children, styles }) {
  if (!styles) return children

  return <Wrapper styles={styles}>{children}</Wrapper>
}

CustomCSSWrapper.propTypes = {
  children: PropTypes.any,
  styles: PropTypes.string,
}

export default CustomCSSWrapper
