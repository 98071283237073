import React, { useEffect } from "react"
import PropTypes from "prop-types"
import useAuthInitialization from "js/hooks/use_auth_initialization"

export const AuthContext = React.createContext()

function AuthContextProvider({ children }) {
  const session = useAuthInitialization()

  useEffect(() => {
    if (session.error) {
      throw new Error(`Authentication error: ${session.error.message}`)
    } else if (session.ready) {
      let refreshAuthTimeout = 0
      refreshAuthTimeout = setTimeout(session.refreshAuth, session.countdown)
      return () => clearTimeout(refreshAuthTimeout)
    } else {
      session.refreshAuth()
    }
  }, [session])

  return <AuthContext.Provider value={session}>{children}</AuthContext.Provider>
}

AuthContextProvider.propTypes = {
  children: PropTypes.any,
}

export default AuthContextProvider

/**
 * {
 *  auth: {
 *      user: {
 *        UserId: '12345',
 *        OrganizationId: '12335',
 *        ImpersonatedOrganizationId: '12335',
 *     },
 *   },
 *    entitlements: {},
 * }
 */
