import { useEffect, useMemo } from "react"
import ReactGA from "react-ga4"

const EVENT_CATEGORY_PREFIX = "Elections Microsite"
const EVENT_TYPES = {
  navigation: "Navigation",
  layouts: "Layouts",
  domains: "Domains",
  themes: "Themes",
}

export function useGAInit(gaID, opts = {}) {
  useEffect(() => {
    const {
      analyticsEnabled = true,
      debug = IS_PRODUCTION ? false : true,
      testMode = IS_PRODUCTION ? false : true,
      ...restOpts
    } = opts
    const gaOpts = {
      debug,
      testMode,
      ...restOpts,
    }
    if (analyticsEnabled) {
      ReactGA.initialize(gaID, gaOpts)
    }
  }, [gaID, opts])
}

export function useGAEventFactory() {
  return useMemo(() => {
    const event = ({ category, action, ...rest }) => {
      ReactGA.event({
        category: `${EVENT_CATEGORY_PREFIX} - ${category}`,
        action,
        ...rest,
      })
    }

    const eventTypes = Object.fromEntries(
      Object.entries(EVENT_TYPES).map(([name, category]) => {
        return [
          name,
          (data) => {
            event({ category, ...data })
          },
        ]
      })
    )

    return {
      event,
      ...eventTypes,
    }
  }, [])
}
