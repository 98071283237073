import { useRef, useEffect } from "react"

function fireResizeMessage() {
  const height = document.documentElement.offsetHeight
  const data = {
    sentinel: "amp",
    type: "embed-size",
    height,
  }
  window.parent.postMessage(data, "*")
}

export default function withResizeMessage(WrappedComponent) {
  function WithResizeMessage(props) {
    const observer = useRef()
    // Create resize observer to observe document and fire resize message
    useEffect(() => {
      observer.current = new window.ResizeObserver(() => {
        fireResizeMessage()
      })
      observer.current.observe(document.documentElement)
      return () => observer.current.disconnect()
    }, [])

    // Create message handler to respond to embed size queries
    useEffect(() => {
      const handler = (msg) => {
        if (msg.data.type !== "embed-size-query") return
        fireResizeMessage()
      }
      window.addEventListener("message", handler)
      return () => window.removeEventListener("message", handler)
    }, [])

    return <WrappedComponent {...props} />
  }

  return WithResizeMessage
}
